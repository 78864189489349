import React from "react";
import TableHeader from "../tableheader";
import TableRow from "../tablerow";

class Schedule extends React.Component {

  render() {
    return (
      <table className="table">
        <thead>
          <TableHeader
            day="Day"
            event="Event"
            notes="Notes"
            time="Time"
            where="Location"
          />
        </thead>
        
        <tbody>
          {/* Wednesday */}
          <TableRow
            color="blue"
            day="Wednesday"
            event="Arrival & Check-in"
            notes="Paperwork and Orientation"
            time="2:00 pm - 7:00 pm"
            where="The Chapel"
          />
          <TableRow
            color="blue"
            event="Dinner"
            notes=""
            time="5:00 pm - 6:00 pm"
            where="Dining Hall"
          />
          
          <TableRow
            color="blue"
            event="Billy Elmquist -"
            notes="&quot;Believe It Like It Is&quot;"
            time="6:30 pm - 7:30 pm"
            where="The Chapel"
          />

            <TableRow
            color="blue"
            event="Iron Sharpeneth Iron -"
            notes=""
            time="7:30 pm - 8:30 pm"
            where="The Chapel"
          />
          
          <TableRow
            color="blue"
            event="Fellowship"
            notes=""
            time="7:30 pm - 10:30 pm"
            where="The Chapel & Dining Hall"
          />
          
          {/* Thursday */}
          <TableRow
            color="purple"
            day="Thursday"
            event="Breakfast"
            notes=""
            time="8:00 am - 9:00 am"
            where="Dining Hall"
          />
          
          <TableRow
            color="purple"
            event="Dan Gross -"
            notes="&quot;Understanding Dispensationalism&quot;"
            time="9:30 am - 10:30 am"
            where="The Chapel"
          />

          <TableRow
            color="purple"
            event="Larry Gabbard -"
            notes="&quot;Understanding The Law&quot;"
            time="11:00 am - 12:00 pm"
            where="The Chapel"
          />
          
          <TableRow
            color="purple"
            event="Lunch"
            notes=""
            time="12:30 pm - 2:00 pm"
            where="Dining Hall"
          />
          
          <TableRow
            color="purple"
            event="Mary Monroe"
            notes="&quot;Women&apos;s Meeting&quot;"
            time="2:00 pm - 3:00 pm"
            where="The Chapel"
          />
          
          <TableRow
            color="purple"
            event="Supper"
            notes=""
            time="5:00 pm - 6:00 pm"
            where="Dining Hall"
          />
          
          <TableRow
            color="purple"
            event="Larry Gabbard -"
            notes="&quot;Eternal Life Now&quot;"
            time="6:30 pm - 7:30 pm"
            where="The Chapel"
          />
          
          <TableRow
            color="purple"
            event="Barney Monroe -"
            notes="&quot;Two Natures?&quot;"
            time="7:30 pm - 8:30 pm"
            where="The Chapel"
          />
          
          <TableRow
            color="purple"
            event="Fellowship"
            notes=""
            time="8:30 pm - 10:30 pm"
            where="The Chapel & Dining Hall"
          />

          {/* Friday */}
          <TableRow
            color="blue"
            day="Friday"
            event="Breakfast"
            notes=""
            time="8:00 am - 9:0 am"
            where="Dining Hall"
          />

          <TableRow
            color="blue"
            event="Adam Nygren -"
            notes="&quot;Order For The Family&quot;"
            time="9:30 am - 10:30 am"
            where="The Chapel"
          />
          
          <TableRow
            color="blue"
            event="Billy Elmquist -"
            notes="&quot;Satans Attack On The Family&quot;"
            time="11:00 am - 12:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="blue"
            event="Lunch"
            notes=""
            time="12:30 pm - 2:00 pm"
            where="Dining Hall"
          />

           <TableRow
            color="blue"
            event="Camp Photo"
            notes="Report to Kadee Luethge"
            time="2:00 pm"
            where="Chapel Yard"
          />
          
          <TableRow
            color="blue"
            event="Supper"
            notes=""
            time="5:00 pm - 6:00 pm"
            where="Dining Hall"
          />

          <TableRow
            color="blue"
            event="Billy Elmquist -"
            notes="&quot;Relating And Fellowship&quot;"
            time="6:30 pm - 7:30 pm"
            where="The Chapel"
          />
          
          <TableRow
            color="blue"
            event="Panel Speakers -"
            notes="&quot;Questions and Answers&quot;"
            time="7:30 pm - 8:30 pm"
            where="The Chapel"
          />

          <TableRow
            color="blue"
            event="Fellowship"
            notes=""
            time="8:30 pm - 10:30 pm"
            where="The Chapel & Dining Hall"
          />

          {/* Saturday */}
          <TableRow
            color="purple"
            day="Saturday"
            event="Breakfast"
            notes=""
            time="8:00 am - 9:00 am"
            where="Dining Hall"
          />

          <TableRow
            color="purple"
            event="Young Men -"
            notes="&quot;Timothy Sessions&quot;"
            time="9:30 am - 11:30 am"
            where="The Chapel"
          />

          <TableRow
            color="purple"
            event="Lunch"
            notes=""
            time="12:30 pm - 2:00 pm"
            where="Dining Hall"
          />

          <TableRow
            color="purple"
            event="Supper"
            notes=""
            time="5:00 pm - 6:00 pm"
            where="Dining Hall"
          />
          
          <TableRow
            color="purple"
            event="Barney Monroe -"
            notes="&quot;Men&apos;s Meeting&quot;"
            time="6:30 pm - 7:30 pm"
            where="The Chapel"
          />
          
          <TableRow
            color="purple"
            event="Ron Sorg"
            notes="&quot;God&apos;s Will Today&quot;"
            time="7:30 pm - 8:30 pm"
            where="The Chapel"
          />

          <TableRow
            color="purple"
            event="Fellowship"
            notes=""
            time="7:30 pm - 10:30 pm"
            where="The Chapel & Dining Hall"
          />

          {/* Sunday */}
          <TableRow
            color="blue"
            day="Sunday"
            event="Breakfast"
            notes=""
            time="8:00 am - 9:00 am"
            where="Dining Hall"
          />

          <TableRow
            color="blue"
            event="Barney Monroe -"
            notes="&quot;Complete But Not Conformed&quot;"
            time="10:00 am - 12:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="blue"
            event="Lunch"
            notes=""
            time="12:30 pm - 2:00 pm"
            where="Dining Hall"
          />

          <TableRow
            color="blue"
            event="Clean Up"
            notes="Cabins, The Chapel, and Dining Hall"
            time="2:00 pm - 3:30 pm"
            where="All Buildings"
          />

        </tbody>
      </table>
    );
  }

}

export default Schedule;
